import React from 'react';

import PageStatic from '../../components/page-static';
import GeneralForm from '../../components/contact/general-form';

function GeneralQuestionsPage(props) {
  return (
    <PageStatic seoTitle={'Contact us. General Questions'} {...props} noCtaBlock>
      <GeneralForm />
    </PageStatic>
  );
}

export default GeneralQuestionsPage;
